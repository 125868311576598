<template>
  <div id="app_wrapper">
    <div style="font-size:1em" class="font-weight-bold text-white">
      <b-navbar v-if="hostname()=='localhost'" variant="warning" fixed="top" class="p-0 animated fadeIn infinite" style="z-index:2000"><div class="w-100 text-center">localhost</div></b-navbar>
      <b-navbar v-else-if="hostname()=='devsfs.hcmfgroup.com'" variant="warning" fixed="top" class="p-0 animated fadeIn infinite" style="z-index:2000"><div class="w-100 text-center">{{$t("Dev Environment")}}</div></b-navbar>
      <b-navbar v-else-if="hostname()=='testsfs.hcmfgroup.com'" variant="warning" fixed="top" class="p-0 animated fadeIn infinite" style="z-index:2000"><div class="w-100 text-center">{{$t("Test Environment")}}</div></b-navbar>
    </div>

    <router-view @login="loginDirect" @logout="logoutDirect"></router-view>
  </div>
</template>

<script>
import Vue from 'vue'
import userPath from '@/router/fullpath'
import * as util from '@/assets/util'

// 攔截器
let myInterceptor

export default {
  name: 'app',
  data () {
    return {
      userData: null,
      menuData: null
    }
  },
  methods: {
    hostname() {
      return window.location.hostname
    },
    signin: function (callback) {
      let vm = this
      // 檢查Token
      let localUser = localStorage
      if (!localUser || !localUser.AccessToken) {
        return vm.$router.push({ path: '/Login', query: { from: vm.$router.currentRoute.path } })
      }
      // 設定請求頭Token
      // this.$http.defaults.headers.Authorization = 'Bearer ' + localUser.AccessToken
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + localUser.AccessToken
      // 取得使用者資料和權限資料
      this.$http.get('/Auth/UserInfo/').then((res) => {
        let userInfo = res.data

        // 取得權限
        let resourcePermission = vm.getPermission(userInfo)
        // 使用資源權限設定攔截器
        vm.setInterceptor(resourcePermission)
        // 取得實際路由
        let allowedRouter = vm.getRoutes(userInfo)
        // 若無可用路由訪問
        if (!allowedRouter || !allowedRouter.length) {
          localStorage.AccessToken = ''
          localStorage.RefreshToken = ''
          // alert('帳號訪問受限，請聯絡系統管理員！')
          this.$router.push({ path: '/' })
          window.location.reload() // 重新載入
        }
        // 動態注入路由
        vm.extendRoutes(allowedRouter)
        // 保存數據作他用，非必需
        vm.menuData = allowedRouter
        vm.userData = userInfo
        // 暫存
        localStorage.userInfo = JSON.stringify(userInfo)
        // alert(localStorage.userInfo)
        // 權限檢驗方法
        Vue.prototype.$_has = function (rArray) {
          let resources = []
          let permission = true
          // 取得權限陣列
          if (Array.isArray(rArray)) {
            for (let entry of rArray) {
              resources = resources.concat(entry.p)
            }
          } else {
            resources = resources.concat(rArray.p)
          }
          // 驗證權限
          for (let entry of resources) {
            if (!resourcePermission[entry]) {
              permission = false
              return permission
            }
          }
          return permission
        }
        // 執行回調
        typeof callback === 'function' && callback()
      })
    },
    loginDirect: function (newPath) {
      this.signin(() => {
        newPath = (newPath === '/Login') ? '/' : newPath
        this.$router.replace({path: newPath || '/'})
      })
    },
    logoutDirect: function () {
      // 清除localStorage
      localStorage.clear()
      // 清除請求權限控制
      this.$http.interceptors.request.eject(myInterceptor)
      // 清除選單權限
      this.$root.hashMenus = {}
      // 清除請求頭Token
      // this.$http.defaults.headers.Authorization = ''
      this.$http.defaults.headers.common['Authorization'] = ''
      this.$http.defaults.headers.common['Accept-Language'] = ''
      // 回到登入頁
      this.$router.replace({path: '/Login'})
      window.location.reload() // 重新載入
    },
    getPermission: function (userInfo) {
      let resourcePermission = {}
      if (Array.isArray(userInfo.Permissions)) {
        for (let entry of userInfo.Permissions) {
          let key = entry.Method.toLowerCase() + ',' + entry.Url
          resourcePermission[key] = true
        }
      }
      return resourcePermission
    },
    setInterceptor: function (resourcePermission) {
      // alert(JSON.stringify(resourcePermission))
      myInterceptor = this.$http.interceptors.request.use(function (config) {
        // 得到請求路徑
        let perName = config.url.replace(config.baseURL, '').replace('/GET', '').replace('/POST', '').split('?')[0]
        // 權限格式1 /path/${param}
        let reg1 = perName.match(/^(\/[^/]+\/)[^/]+$/)
        if (reg1) {
          perName = reg1[1] + '**'
        }
        // 權限格式2 /path/path/${param}
        let reg2 = perName.match(/^(\/[^/]+\/[^/]+\/)[^/]+$/)
        if (reg2) {
          perName = reg2[1] + '**'
        }
        // alert(perName)
        // 驗證權限
        if (!resourcePermission[config.method + ',' + perName]) {
          // 調試訊息
          console.warn(resourcePermission, config.method + ',' + perName)
          // alert('無訪問權限，請聯絡系統管理員！')
          // 攔截請求
          return Promise.reject(new Error('no permission'))
        }
        return config
      })
    },
    getRoutes: function (userInfo) {
      let allowedRouter = []
      if (!userInfo.Menus) {
        console.warn(userInfo)
        return allowedRouter
      }
      // 將選單轉成多維陣列
      let arrayMenus = util.buildMenu(userInfo.Menus)
      // alert(JSON.stringify(arrayMenus))
      // 暫存
      localStorage.arrayMenus = JSON.stringify(arrayMenus)
      // 將多維陣列轉成物件
      let hashMenus = {}
      let setMenu2Hash = function (array) {
        array.map(x => {
          if (x.Url) {
            hashMenus[x.Url] = true
            if (Array.isArray(x.Children)) {
              setMenu2Hash(x.Children)
            }
          }
        })
      }
      setMenu2Hash(arrayMenus)
      // 全局掛載hashMenus，用於實現路由守衛
      this.$root.hashMenus = hashMenus
      // 篩選本地路由方法
      let findLocalRoute = function (array, base) {
        let replyResult = []
        for (let entry of array) {
          let pathKey = (base ? base + '/' : '') + entry.path
          if (hashMenus[pathKey]) {
            if (Array.isArray(entry.Children)) {
              entry.Children = findLocalRoute(entry.Children, entry.path)
            }
            replyResult.push(entry)
          }
        }
        if (base) {
          return replyResult
        } else {
          allowedRouter = allowedRouter.concat(replyResult)
        }
      }
      let originPath = this._.cloneDeep(userPath[0].children)
      findLocalRoute(originPath)
      return allowedRouter
    },
    extendRoutes: function (allowedRouter) {
      let vm = this
      let actualRouter = this._.cloneDeep(allowedRouter)
      actualRouter.map(x => {
        // 複製子選單到meta用於實現導航相關效果，非必需
        if (x.Children) {
          if (!x.meta) x.meta = {}
          x.meta.Children = x.Children
        }
        // 為動態路由添加獨享守衛
        x.beforeEnter = function (to, from, next) {
          if (vm.$root.hashMenus[to.path]) {
            next()
          } else {
            next('/401')
          }
        }
        return x.beforeEnter
      })
      let originPath = this._.cloneDeep(userPath)
      originPath[0].Children = actualRouter
      // alert(JSON.stringify(originPath[0].Children))
      // 注入路由
      vm.$router.addRoutes(originPath.concat([{
        path: '*',
        redirect: '/404'
      }]))
    }
  },
  created: function (newPath) {
    if(!window.location.hash.startsWith('#/QaBoard') && !window.location.hash.startsWith('#/LineBoard')) {
      this.signin()
    }
  }
}
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap/dist/css/bootstrap.css';
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';

  .form-control {
    height: auto;
  }
  dt, dd {
    margin-bottom: .5rem;
  }
  
  .lightbox {
    z-index: 9999 !important;
  }
</style>
