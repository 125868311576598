import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/lang'

// 防止出現錯誤訊息：Avoided redundant navigation to current location...
//console.log(Router.prototype);
// 把Router 原型链push函数保存一份
let originPush = Router.prototype.push
// 把Router 原型链replace函数保存一份
let originReplace = Router.prototype.replace
// 重写push函数
Router.prototype.push = function(location,resolve,reject){
  if (resolve && reject){
    // call 和 apply
    // 相同点：都能执行函数一次，都能篡改函数的上下文一次
    // 不同点，call  多个参数用,隔开，apply  多个参数为数组
    originPush.call(this, location, resolve, reject)
  }else{
    originPush.call(this, location, () => { }, () => { })
  }
}
// 重写replace函数
Router.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    // call 和 apply
    // 相同点：都能执行函数一次，都能篡改函数的上下文一次
    // 不同点，call  多个参数用,隔开，apply  多个参数为数组
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(this, location, () => { }, () => { })
  }
}

// Views
const Page404 = () => import('@/views/Page404')
const Page500 = () => import('@/views/Page500')
const Login = () => import('@/views/Login')
const Register = () => import('@/views/Register')
const LineBoard = () => import('@/views/LineBoard')
const LineBoard2 = () => import('@/views/LineBoard2')
const QaBoard = () => import('@/views/QaBoard')

Vue.use(Router)

let baseRoute = [
  {
    path: '/404',
    name: 'Router.Page404',
    component: Page404
  },
  {
    path: '/500',
    name: 'Router.Page500',
    component: Page500
  },
  {
    path: '/Login',
    name: 'Router.Login',
    component: Login
  },
  {
    path: '/Register',
    name: 'Router.Register',
    component: Register
  },
  {
    path: '/LineBoard',
    name: 'Router.LineBoard',
    component: LineBoard
  },
  {
    path: '/LineBoard2',
    name: 'Router.LineBoard2',
    component: LineBoard2
  },
  {
    path: '/QaBoard',
    name: 'Router.QaBoard',
    component: QaBoard
  },
]

let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: baseRoute
})

router.beforeEach((to, from, next) => {
  let routeName = i18n.t(to.name)
  window.document.title = (routeName ? routeName + ' - ' : '') + 'HC-SfsCloud'
  next()
})

export default router
