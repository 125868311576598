// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es/promise' //有修改過
import 'core-js/es/string' //有修改過
import 'core-js/es/array' //有修改過
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import App from './App'
import router from './router'

//vuex
import store from './store'

//axios
import Axios from '@/api/index'
Vue.prototype.$http = Axios

//i18n
import i18n from '@/lang'

//bootstrap-vue
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css' //在App.vue叫用
// import 'bootstrap-vue/dist/bootstrap-vue.css' //在App.vue叫用
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

//vuejs-dialog
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog);

//config
import { CONFIG as SiteConfig } from '@/config'
Object.defineProperty(Vue.prototype, '$conf', { value: SiteConfig })

//vue-lodash
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { name: 'custom', lodash: lodash })

//vue-echarts
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/bar';
// import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/scatter';
// import 'echarts/lib/chart/radar';
// import 'echarts/lib/chart/map';
// import 'echarts/lib/chart/treemap';
// import 'echarts/lib/chart/graph';
import 'echarts/lib/chart/gauge';
// import 'echarts/lib/chart/funnel';
// import 'echarts/lib/chart/parallel';
// import 'echarts/lib/chart/sankey';
// import 'echarts/lib/chart/boxplot';
// import 'echarts/lib/chart/candlestick';
// import 'echarts/lib/chart/effectScatter';
// import 'echarts/lib/chart/lines';
// import 'echarts/lib/chart/heatmap';
// import 'echarts/lib/component/graphic';
// import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
// import 'echarts/lib/component/tooltip';
// import 'echarts/lib/component/polar';
// import 'echarts/lib/component/geo';
// import 'echarts/lib/component/parallel';
// import 'echarts/lib/component/singleAxis';
// import 'echarts/lib/component/brush';
import 'echarts/lib/component/title';
// import 'echarts/lib/component/dataZoom';
// import 'echarts/lib/component/visualMap';
// import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';
// import 'echarts/lib/component/markArea';
// import 'echarts/lib/component/timeline';
// import 'echarts/lib/component/toolbox';
Vue.component('v-chart', ECharts)

//vue-moment
// Vue.use(require('vue-moment'));
import moment from 'moment'
Vue.prototype.$moment = moment

//vue-my-photos
import Lightbox from 'vue-my-photos'
Vue.component('lightbox', Lightbox);

//vue-marquee-slider
import VueMarqueeSlider from 'vue-marquee-slider';
Vue.use(VueMarqueeSlider)

// todo
// cssVars()

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
