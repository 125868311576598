const CONFIG = {
    Locale: 'zh-TW',
    // Locale: 'zh-TW', //上線時要修改為此
    // Locale: 'en-US', //上線時要修改為此
    LocalApiUrl: 'https://sfsapi.hcmfgroup.com'
    // LocalApiUrl: 'https://localhost:51' //SSL
    // LocalApiUrl: 'http://devsfs.hcmfgroup.com:50' //開發環境(雲端) //開發時要修改為此
    // LocalApiUrl: 'http://testsfs.hcmfgroup.com:50' //測試環境(雲端) //測試時要修改為此
    // LocalApiUrl: 'http://sfs.hcmfgroup.com:50' //正式環境(雲端) //上線時要修改為此
    // LocalApiUrl: 'https://sfsapi.hcmfgroup.com' //正式環境(雲端) //上線時要修改為此 //SSL
}

export { CONFIG }
