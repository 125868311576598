// 選單
export const buildMenu = function (array, ckey) {
  let menuData = []
  let indexKeys = Array.isArray(array) ? array.map(x => x.Id) : []
  ckey = ckey || 'ParentId'
  for (let entry of array) {
    // 一級選單
    if (!entry[ckey] || (entry[ckey] === entry.Id)) {
      delete entry[ckey]
      menuData.push(deepcopy(entry)) // 深拷貝
    } else if (Array.isArray(indexKeys)) {
      // 檢查ckey有效性
      let parentIndex = indexKeys.findIndex(function (id) {
        return id === entry[ckey]
      })
      if (parentIndex === -1) {
        menuData.push(entry)
      }
    }
  }
  let findChildren = function (parentArr) {
    if (Array.isArray(parentArr) && parentArr.length) {
      for (let parentNode of parentArr) {
        for (let node of array) {
          if (parentNode.Id === node[ckey]) {
            if (parentNode.Children) {
              parentNode.Children.push(node)
            } else {
              parentNode.Children = [node]
            }
          }
        }
        if (parentNode.Children) {
          findChildren(parentNode.Children)
        }
      }
    }
  }
  findChildren(menuData)
  return menuData
}
// 深拷貝
export const deepcopy = function (source) {
  if (!source) {
    return source
  }
  let sourceCopy = source instanceof Array ? [] : {}
  for (let item in source) {
    sourceCopy[item] = typeof source[item] === 'object' ? deepcopy(source[item]) : source[item]
  }
  return sourceCopy
}
// 產生隨機數
export const getUUID = function (len) {
  len = len || 6
  len = parseInt(len, 10)
  len = isNaN(len) ? 6 : len
  var seed = '0123456789abcdefghijklmnopqrstubwxyzABCEDFGHIJKLMNOPQRSTUVWXYZ'
  var seedLen = seed.length - 1
  var uuid = ''
  while (len--) {
    uuid += seed[Math.round(Math.random() * seedLen)]
  }
  return uuid
}
// // 日期格式化
// export const dateFormat = function (source, ignoreMinute) {
//   var myDate
//   var separate = '-'
//   var minute = ''
//   if (source === void (0)) {
//     source = new Date()
//   }
//   if (source) {
//     if (source.split) {
//       source = source.replace(/-/g, '/')
//     } else if (isNaN(parseInt(source))) {
//       source = source.toString().replace(/-/g, '/')
//     } else {
//       source = new Date(source)
//     }

//     if (new Date(source) && (new Date(source)).getDate) {
//       myDate = new Date(source)
//       if (!ignoreMinute) {
//         minute = (myDate.getHours() < 10 ? ' 0' : ' ') + myDate.getHours() + ':' + (myDate.getMinutes() < 10 ? '0' : '') + myDate.getMinutes()
//       }
//       return myDate.getFullYear() + separate + (myDate.getMonth() + 1) + separate + (myDate.getDate() < 10 ? '0' : '') + myDate.getDate() + minute
//     } else {
//       return source.slice(0, 16)
//     }
//   } else {
//     return source
//   }
// }
