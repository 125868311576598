import axios from 'axios'
import { objectToFormData } from 'object-to-formdata'
import i18n from '@/lang'
import router from '@/router'
require('es6-promise').polyfill()

import Vue from 'vue'
import { CONFIG as SiteConfig } from '@/config'

let baseUrl = SiteConfig.LocalApiUrl + '/api'
export const instance = axios.create({
  baseURL: baseUrl,
  timeout: 600000
})

// instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// instance.defaults.headers.get['Accepts'] = 'application/json'
// instance.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'

// POST傳參序列化(添加請求攔截器)
// 在發送請求之前做某件事
instance.interceptors.request.use(async (config) => { //使用async
  // 設置以 form 表單的形式提交參數，如果以 JSON 的形式提交表單，可忽略
  if (config.method === 'post' || config.method === 'put') {
    // JSON 轉換為 FormData
    const options = {
      indices: true
    }
    config.data = objectToFormData(config.data, options)
  }

  // Localization
  config.headers.common['Accept-Language'] = i18n.locale
  // alert(i18n.locale)

  // Auth Check
  await axios.create({
    baseURL: baseUrl + '/Auth/AuthCheck/',
    headers: { 'Authorization': 'Bearer ' + localStorage.AccessToken },
  }).get(baseUrl + '/Auth/AuthCheck/').then(() => {
    //已授權
  }).catch(async (error) => { //使用async
    if (error.response.status == 401) { //未授權錯誤
      //Refresh Token
      if (localStorage.AccessToken && localStorage.RefreshToken) {
        let token = {
          AccessToken: localStorage.AccessToken,
          RefreshToken: localStorage.RefreshToken
        };
        await axios.post(baseUrl + '/Auth/UpdateToken/', token).then((res) => {
          // 成功
          if (res.data.AccessToken && res.data.RefreshToken) {
            // 儲存 token
            localStorage.AccessToken = res.data.AccessToken
            localStorage.RefreshToken = res.data.RefreshToken
          }
        }).catch(async (error) => {
          // alert(JSON.stringify(error))
          error.response.status = 401
        })
      }
    }
  })

  // 修改config的headers
  config.headers.common['Authorization'] = 'Bearer ' + localStorage.AccessToken
  
  return config
}, error => {
  return Promise.reject(error)
})

// 返回狀態判斷(添加回應攔截器)
instance.interceptors.response.use(res => {
  // 對回應資料做些事
  return res
}, error => {
  if (error.response) {
    const vue = new Vue();  
    switch (error.response.status) {
      case 400:
        // Vue.dialog.alert('請求參數異常', {okText: i18n.t('Ok')})
        break
      case 401:
        Vue.dialog.alert('未授權錯誤', {okText: i18n.t('Ok')})
        localStorage.clear() // 清除localStorage
        router.replace({path: '/Login'}) // 回到登入頁
        window.location.reload() // 重新載入
        break
      case 403:
        Vue.dialog.alert('無訪問權限，請連絡系統管理員', {okText: i18n.t('Ok')})
        break
      case 404:
        // Vue.dialog.alert('查詢不到資料', {okText: i18n.t('Ok')})
        vue.$bvToast.toast(i18n.t('There are no records matching your request'), {variant: 'danger'})
        break
      case 500:
        Vue.dialog.alert('服務端異常，請連絡系統管理員', {okText: i18n.t('Ok')})
        break
      default:
        Vue.dialog.alert('錯誤，請連絡系統管理員', {okText: i18n.t('Ok')})
    }
  }
  // axios 配置裡必須要 return Promise.reject(error.response.data) 才能拿到錯誤欄位
  return Promise.reject(error.response.data)
})

export default instance
