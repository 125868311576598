import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    //目標產量
    goalProduceQty: () => (day, round, roundTotalValidSeconds) => {
      let result = day.GoalProduceQty
      if (round!==undefined) {
        result = day.GoalProduceQty + round.GoalProduceQty //(生產日數值+本回數值)
      }

      //注意計算方式
      let nowGoalProduceQty = 0
      if (round && round.RoundValidTimes) {
        for (let entry of round.RoundValidTimes) { //正常應該只會有一筆資料
          let nowTotalValidSeconds = roundTotalValidSeconds - round.TotalValidSeconds
          nowGoalProduceQty += (entry.CycleTime === 0) ? 0 : nowTotalValidSeconds / entry.CycleTime
        }
      }
      result = result + nowGoalProduceQty
      
      return result
    },
    //實際產量
    realProduceQty: () => (day, round) => {
      let result = day.RealProduceQty
      if (round!==undefined) {
        result = day.RealProduceQty + round.RealProduceQty //(生產日數值+本回數值)
      }
      return result
    },
    //目標CT
    goalCycleTime: () => (day, round) => {
      let result = day.GoalCycleTime
      if (round!==undefined) {
        let roundTotalRoundCounts = (round.TotalRoundCounts===undefined) ? 0 : round.TotalRoundCounts
        result = (day.GoalCycleTime * day.TotalRoundCounts + round.GoalCycleTime) / (day.TotalRoundCounts + roundTotalRoundCounts) //(生產日數值*生產日回合數+本回數值)/(生產日回合數+本回回合數)
      }
      return result
    },
    //實際CT
    realCycleTime: () => (day, round, roundTotalValidSeconds, realProduceQty) => {
      let totalValidSeconds = roundTotalValidSeconds + day.TotalValidSeconds //(生產日數值+本回數值)

      let result = day.RealCycleTime
      if (round!==undefined) {
        result = (realProduceQty === 0) ? 0 : (totalValidSeconds / realProduceQty) //目前執行中的 (要和server算法一樣)
      }
      return result
    },
    //目標人時均量
    goalManHourQty: () => (day, round, roundTotalValidSeconds, goalProduceQty, goalHeads) => {
      let totalValidSeconds = roundTotalValidSeconds + day.TotalValidSeconds //(生產日數值+本回數值)

      let result = day.GoalManHourQty
      if (round!==undefined) {
        result = (totalValidSeconds === 0 || goalHeads === 0) ? 0 : ((goalProduceQty / totalValidSeconds * 60 * 60) / goalHeads) //目前執行中的 (要和server算法一樣)
      }
      return result
    },
    //實際人時均量
    realManHourQty: () => (day, round, roundTotalValidSeconds, realProduceQty, realHeads) => {
      let totalValidSeconds = roundTotalValidSeconds + day.TotalValidSeconds //(生產日數值+本回數值)

      let result = day.RealManHourQty
      if (round!==undefined) {
        result = (totalValidSeconds === 0 || realHeads === 0) ? 0 : ((realProduceQty / totalValidSeconds * 60 * 60) / realHeads) //目前執行中的 (要和server算法一樣)
      }
      return result
    },
    //目標時產量
    goalHourQty: () => (day, round, roundTotalValidSeconds, goalProduceQty) => {
      let totalValidSeconds = roundTotalValidSeconds + day.TotalValidSeconds //(生產日數值+本回數值)

      let result = day.GoalHourQty
      if (round!==undefined) {
        result = (totalValidSeconds === 0) ? 0 : (goalProduceQty / totalValidSeconds * 60 * 60) //目前執行中的 (要和server算法一樣)
      }
      return result
    },
    //實際時產量
    realHourQty: () => (day, round, roundTotalValidSeconds, realProduceQty) => {
      let totalValidSeconds = roundTotalValidSeconds + day.TotalValidSeconds //(生產日數值+本回數值)

      let result = day.RealHourQty
      if (round!==undefined) {
        result = (totalValidSeconds === 0) ? 0 : (realProduceQty / totalValidSeconds * 60 * 60) //目前執行中的 (要和server算法一樣)
      }
      return result
    },
    //目標人數
    goalHeads: () => (day, round) => {
      let result = day.GoalHeads
      if (round!==undefined) {
        result = Math.max(day.GoalHeads, round.GoalHeads) //取最大值(生產日數值,本回數值)
      }
      return result
    },
    //實際人數
    realHeads: () => (day, round) => {
      let result = day.RealHeads
      if (round!==undefined) {
        result = Math.max(day.RealHeads, round.RealHeads) //取最大值(生產日數值,本回數值)
      }
      return result
    },

    //良品
    goodQty: () => (day, round) => {
      let result = day.GoodQty
      if (round!==undefined) {
        result = day.GoodQty + round.GoodQty //(生產日數值+本回數值)
      }
      return result
    },
    //不良品-加工不良數
    machiningQty: () => (day, round) => {
      let result = day.MachiningQty
      if (round!==undefined) {
        result = day.MachiningQty + round.MachiningQty //(生產日數值+本回數值)
      }
      return result
    },
    //不良品-素材部品不良數
    partsQty: () => (day, round) => {
      let result = day.PartsQty
      if (round!==undefined) {
        result = day.PartsQty + round.PartsQty //(生產日數值+本回數值)
      }
      return result
    },
    //不良品-必要廢卻不良數
    wasteQty: () => (day, round) => {
      let result = day.WasteQty
      if (round!==undefined) {
        result = day.WasteQty + round.WasteQty //(生產日數值+本回數值)
      }
      return result
    },
    
    //稼動率(ForLine) = (計畫工作時間-停線時間)a_y / 計畫工作時間a_x
    dayAvailabilityForLine: () => (day, roundTotalLoadingSeconds, roundTotalStopSeconds) => {
      //計畫工作時間 = 465分（27900秒）
      //實際工作時間 = 465分 - a分（停線時間） = b分
      let totalLoadingSeconds = (day!==undefined) ?
        day.TotalLoadingSeconds : //已經是 取不重覆的加總值
        roundTotalLoadingSeconds
      let totalStopSeconds = (day!==undefined) ?
        roundTotalStopSeconds + day.TotalStopSeconds : //(生產日數值+本回數值)
        roundTotalStopSeconds
      
      //開線損失秒數
      let lostOpeningTimeSeconds = (day!==undefined) ? day.LostOpeningTimeSeconds : 0;
      
      let a_x = totalLoadingSeconds - lostOpeningTimeSeconds //改成用開線時間來開始算稼動率(這裡就要減lostOpeningTimeSeconds)
      let a_y = a_x - totalStopSeconds //改成用開線時間來開始算稼動率(這裡就不減lostOpeningTimeSeconds)
      let result = (a_x === 0) ? '0' : (a_y / a_x * 100).toFixed(2) //目前執行中的 (要和server算法一樣)
      return parseFloat(result)
    },
    //稼動率(ForMaterial) = (生產時間-生產期間停線時間)a_y / 生產時間a_x
    dayAvailability: () => (day, roundTotalRoundSeconds, roundTotalRestSeconds, roundTotalStopSeconds) => {
      let totalRoundSeconds = (day!==undefined) ?
        roundTotalRoundSeconds + day.TotalRoundSeconds : //(生產日數值+本回數值)
        roundTotalRoundSeconds
      let totalRestSeconds = (day!==undefined) ?
        roundTotalRestSeconds + day.TotalRestSeconds : //(生產日數值+本回數值)
        roundTotalRestSeconds
      let totalStopSeconds = (day!==undefined) ?
        roundTotalStopSeconds + day.TotalStopSeconds : //(生產日數值+本回數值)
        roundTotalStopSeconds
      
      let a_x = totalRoundSeconds - totalRestSeconds
      let a_y = a_x - totalStopSeconds
      let result = (a_x === 0) ? '0' : (a_y / a_x * 100).toFixed(2) //目前執行中的 (要和server算法一樣)
      return parseFloat(result)
    },
    //效率 = 實際產量p_y / 目標產量p_x
    dayPerformance: () => (goalProduceQty, realProduceQty) => {
      let p_x = Math.round(goalProduceQty) //要使用Math.round
      let p_y = Math.round(realProduceQty) //要使用Math.round
      let result = (p_x === 0) ? '0' : (p_y / p_x * 100).toFixed(2) //目前執行中的 (要和server算法一樣)
      return parseFloat(result)
    },
    //良率 = 良品數q_y / 實際產量q_x
    dayQuality: () => (realProduceQty, goodQty) => {
      let q_x = realProduceQty
      let q_y = goodQty
      let result = (q_x === 0) ? '0' : (q_y / q_x * 100).toFixed(2) //目前執行中的 (要和server算法一樣)
      return parseFloat(result)
    },
    //isLineAlive
    isLineAlive: () => (utcAliveAt) => {
      if (utcAliveAt===undefined) return false
      
      let utcNow = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      let diffMinutes = moment(utcNow).diff(moment(utcAliveAt), 'minutes')
      return diffMinutes<10 ? true : false //小於10分鐘視為連線中
    },
    //genRealtime
    genRealtime: (state, getters) => (item) => {
      let realtimeItem = Vue._.cloneDeep(item) //DeepCopy
      
      //為了排序方便要產生出屬性與值
      realtimeItem.UtcAliveAt = (item.LineAlive === undefined) ? '' : item.LineAlive.UtcAliveAt

      //各產品
      let oriYields = item.Yields
      let realtimeYields = realtimeItem.Yields

      //產線
      let dayYield = item.DayYield
      let dayStartedRound = item.DayStartedRound
      
      //計算秒數
      let roundTotalLoadingSeconds = 0
      let roundTotalRestSeconds = 0
      let roundTotalStopSeconds = 0
      let roundTotalValidSeconds = 0
      let roundTotalRunningSeconds = 0
      let roundTotalExceptionDeductibleSeconds = 0
      let roundTotalExceptionNonDeductibleSeconds = 0
      let roundTotalRoundSeconds = 0
      if (dayStartedRound !== undefined) {
        let utcOffset = parseInt(dayStartedRound.UtcOffset) //UTC時差
        let dtNow = moment.utc().add(utcOffset, 'hours').format('YYYY-MM-DD HH:mm:ss')
        if (realtimeItem.UtcAliveAt && !getters.isLineAlive(realtimeItem.UtcAliveAt)) {
          dtNow = moment(realtimeItem.UtcAliveAt).add(utcOffset, 'hours').format('YYYY-MM-DD HH:mm:ss')
        }

        //roundTotalLoadingSeconds
        roundTotalLoadingSeconds = dayStartedRound.TotalLoadingSeconds
        //roundTotalRunningSeconds
        roundTotalRunningSeconds = dayStartedRound.TotalRunningSeconds
        if (dayStartedRound.RoundRunningTimes) {
          for (let entry of dayStartedRound.RoundRunningTimes) {
            let totalSeconds = (entry.EndedAt!==undefined) ? entry.TotalSeconds : moment(dtNow).diff(moment(entry.StartedAt), 'seconds')
            roundTotalRunningSeconds += totalSeconds
          }
        }
        //roundTotalRestSeconds
        roundTotalRestSeconds = dayStartedRound.TotalRestSeconds
        if (dayStartedRound.RoundRestTimes) {
          for (let entry of dayStartedRound.RoundRestTimes) {
            let totalSeconds = (entry.EndedAt!==undefined) ? entry.TotalSeconds : moment(dtNow).diff(moment(entry.StartedAt), 'seconds')
            roundTotalRestSeconds += totalSeconds
          }
        }
        //roundTotalStopSeconds
        roundTotalStopSeconds = dayStartedRound.TotalStopSeconds
        if (dayStartedRound.RoundStopTimes) {
          for (let entry of dayStartedRound.RoundStopTimes) {
            let totalSeconds = (entry.EndedAt!==undefined) ? entry.TotalSeconds : moment(dtNow).diff(moment(entry.StartedAt), 'seconds')
            roundTotalStopSeconds += totalSeconds
          }
        }
        //roundTotalValidSeconds
        roundTotalValidSeconds = dayStartedRound.TotalValidSeconds
        if (dayStartedRound.RoundValidTimes) {
          for (let entry of dayStartedRound.RoundValidTimes) {
            let totalSeconds = (entry.EndedAt!==undefined) ? entry.TotalSeconds : moment(dtNow).diff(moment(entry.StartedAt), 'seconds')
            roundTotalValidSeconds += totalSeconds
          }
        }
        if (dayStartedRound.RoundExceptionTimes) {
          //roundTotalExceptionDeductibleSeconds
          roundTotalExceptionDeductibleSeconds = dayStartedRound.TotalExceptionDeductibleSeconds
          let deductibleSeconds = dayStartedRound.RoundExceptionTimes.filter(x => x.IsDeductible === 'T')
          for (let entry of deductibleSeconds) {
            let totalSeconds = (entry.EndedAt!==undefined) ? entry.TotalSeconds : moment(dtNow).diff(moment(entry.StartedAt), 'seconds')
            roundTotalExceptionDeductibleSeconds += totalSeconds
          }
          //roundTotalExceptionNonDeductibleSeconds
          roundTotalExceptionNonDeductibleSeconds = dayStartedRound.TotalExceptionNonDeductibleSeconds
          let nonDeductibleSeconds = dayStartedRound.RoundExceptionTimes.filter(x => x.IsDeductible === 'F')
          for (let entry of nonDeductibleSeconds) {
            let totalSeconds = (entry.EndedAt!==undefined) ? entry.TotalSeconds : moment(dtNow).diff(moment(entry.StartedAt), 'seconds')
            roundTotalExceptionNonDeductibleSeconds += totalSeconds
          }
        }
        //roundTotalRoundSeconds
        roundTotalRoundSeconds = dayStartedRound.TotalSeconds
        let totalSeconds = (dayStartedRound.EndedAt!==undefined) ? dayStartedRound.TotalSeconds : moment(dtNow).diff(moment(dayStartedRound.StartedAt), 'seconds')
        roundTotalRoundSeconds += totalSeconds
      }
      
      //產線
      if (dayYield !== undefined) {
        //realtimeItem.TotalLoadingSeconds
        realtimeItem.TotalLoadingSeconds = dayYield.TotalLoadingSeconds //已經是 取不重覆的加總值
        //realtimeItem.TotalRestSeconds
        realtimeItem.TotalRestSeconds = dayYield.TotalRestSeconds + roundTotalRestSeconds
        //realtimeItem.TotalStopSeconds
        realtimeItem.TotalStopSeconds = dayYield.TotalStopSeconds + roundTotalStopSeconds
        //realtimeItem.TotalValidSeconds
        realtimeItem.TotalValidSeconds = dayYield.TotalValidSeconds + roundTotalValidSeconds
        //realtimeItem.TotalRunningSeconds
        realtimeItem.TotalRunningSeconds = dayYield.TotalRunningSeconds + roundTotalRunningSeconds
        //realtimeItem.TotalExceptionDeductibleSeconds
        realtimeItem.TotalExceptionDeductibleSeconds = dayYield.TotalExceptionDeductibleSeconds + roundTotalExceptionDeductibleSeconds
        //realtimeItem.TotalExceptionNonDeductibleSeconds
        realtimeItem.TotalExceptionNonDeductibleSeconds = dayYield.TotalExceptionNonDeductibleSeconds + roundTotalExceptionNonDeductibleSeconds
        //realtimeItem.TotalRoundSeconds
        realtimeItem.TotalRoundSeconds = dayYield.TotalRoundSeconds + roundTotalRoundSeconds
        
        let goalHeads = getters.goalHeads(dayYield, dayStartedRound)
        let realHeads = getters.realHeads(dayYield, dayStartedRound)
        let goalProduceQty = getters.goalProduceQty(dayYield, dayStartedRound, roundTotalValidSeconds)
        let realProduceQty = getters.realProduceQty(dayYield, dayStartedRound)
        let goodQty = getters.goodQty(dayYield, dayStartedRound)
        
        //realtimeItem.GoalHeads
        realtimeItem.GoalHeads = goalHeads
        //realtimeItem.RealHeads
        realtimeItem.RealHeads = realHeads
        //realtimeItem.GoalCycleTime
        realtimeItem.GoalCycleTime = parseFloat(getters.goalCycleTime(dayYield, dayStartedRound).toFixed(2))
        //realtimeItem.RealCycleTime
        realtimeItem.RealCycleTime = parseFloat(getters.realCycleTime(dayYield, dayStartedRound, roundTotalValidSeconds, realProduceQty).toFixed(2))
        //realtimeItem.GoalProduceQty
        realtimeItem.GoalProduceQty = Math.round(goalProduceQty) //要使用Math.round
        //realtimeItem.RealProduceQty
        realtimeItem.RealProduceQty = realProduceQty
        //realtimeItem.GoalManHourQty
        realtimeItem.GoalManHourQty = Math.ceil(getters.goalManHourQty(dayYield, dayStartedRound, roundTotalValidSeconds, goalProduceQty, goalHeads)) //要使用Math.ceil
        //realtimeItem.RealManHourQty
        realtimeItem.RealManHourQty = Math.ceil(getters.realManHourQty(dayYield, dayStartedRound, roundTotalValidSeconds, realProduceQty, realHeads)) //要使用Math.ceil
        //realtimeItem.GoalHourQty
        realtimeItem.GoalHourQty = Math.round(getters.goalHourQty(dayYield, dayStartedRound, roundTotalValidSeconds, goalProduceQty)) //要使用Math.round
        //realtimeItem.RealHourQty
        realtimeItem.RealHourQty = Math.ceil(getters.realHourQty(dayYield, dayStartedRound, roundTotalValidSeconds, realProduceQty)) //要使用Math.ceil
        //realtimeItem.GoodQty
        realtimeItem.GoodQty = goodQty
        //realtimeItem.MachiningQty
        realtimeItem.MachiningQty = getters.machiningQty(dayYield, dayStartedRound)
        //realtimeItem.PartsQty
        realtimeItem.PartsQty = getters.partsQty(dayYield, dayStartedRound)
        //realtimeItem.WasteQty
        realtimeItem.WasteQty = getters.wasteQty(dayYield, dayStartedRound)

        //自動計算欄位
        //realtimeItem.Availability
        realtimeItem.Availability = getters.dayAvailabilityForLine(dayYield, roundTotalLoadingSeconds, roundTotalStopSeconds)
        //realtimeItem.Performance
        realtimeItem.Performance = getters.dayPerformance(realtimeItem.GoalProduceQty, realtimeItem.RealProduceQty)
        //realtimeItem.Quality
        realtimeItem.Quality = getters.dayQuality(realtimeItem.RealProduceQty, realtimeItem.GoodQty)
        //realtimeItem.Oee
        realtimeItem.Oee = parseFloat((realtimeItem.Availability * realtimeItem.Performance * realtimeItem.Quality / 10000).toFixed(2)) //目前執行中的 (要和server算法一樣)
      }

      //各產品
      if (realtimeYields !== undefined && Array.isArray(realtimeYields)) {
        for (let {entry, index} of realtimeYields.map((entry, index) => ({ entry, index }))) {
          if (dayStartedRound !== undefined) {
            if (entry && entry.LineId === dayStartedRound.LineId && entry.MaterialId === dayStartedRound.MaterialId) {
              //entry.TotalLoadingSeconds
              entry.TotalLoadingSeconds += roundTotalLoadingSeconds
              //entry.TotalRestSeconds
              entry.TotalRestSeconds += roundTotalRestSeconds
              //entry.TotalStopSeconds
              entry.TotalStopSeconds += roundTotalStopSeconds
              //entry.TotalValidSeconds
              entry.TotalValidSeconds += roundTotalValidSeconds
              //entry.TotalRunningSeconds
              entry.TotalRunningSeconds += roundTotalRunningSeconds
              //entry.TotalExceptionDeductibleSeconds
              entry.TotalExceptionDeductibleSeconds += roundTotalExceptionDeductibleSeconds
              //entry.TotalExceptionNonDeductibleSeconds
              entry.TotalExceptionNonDeductibleSeconds += roundTotalExceptionNonDeductibleSeconds
              //entry.TotalRoundSeconds
              entry.TotalRoundSeconds += roundTotalRoundSeconds
        
              let goalHeads = getters.goalHeads(oriYields[index], dayStartedRound)
              let realHeads = getters.realHeads(oriYields[index], dayStartedRound)
              let goalProduceQty = getters.goalProduceQty(oriYields[index], dayStartedRound, roundTotalValidSeconds)
              let realProduceQty = getters.realProduceQty(oriYields[index], dayStartedRound)
              let goodQty = getters.goodQty(oriYields[index], dayStartedRound)

              //entry.GoalHeads
              entry.GoalHeads = goalHeads
              //entry.RealHeads
              entry.RealHeads = realHeads
              //entry.GoalCycleTime
              entry.GoalCycleTime = parseFloat(getters.goalCycleTime(oriYields[index], dayStartedRound).toFixed(2))
              //entry.RealCycleTime
              entry.RealCycleTime = parseFloat(getters.realCycleTime(oriYields[index], dayStartedRound, roundTotalValidSeconds, realProduceQty).toFixed(2))
              //entry.GoalProduceQty
              entry.GoalProduceQty = goalProduceQty
              //entry.RealProduceQty
              entry.RealProduceQty = realProduceQty
              //entry.GoalManHourQty
              entry.GoalManHourQty = getters.goalManHourQty(oriYields[index], dayStartedRound, roundTotalValidSeconds, goalProduceQty, goalHeads)
              //entry.RealManHourQty
              entry.RealManHourQty = getters.realManHourQty(oriYields[index], dayStartedRound, roundTotalValidSeconds, realProduceQty, realHeads)
              //entry.GoalHourQty
              entry.GoalHourQty = getters.goalHourQty(oriYields[index], dayStartedRound, roundTotalValidSeconds, goalProduceQty)
              //entry.RealHourQty
              entry.RealHourQty = getters.realHourQty(oriYields[index], dayStartedRound, roundTotalValidSeconds, realProduceQty)
              //entry.GoodQty
              entry.GoodQty = goodQty
              //entry.MachiningQty
              entry.MachiningQty = getters.machiningQty(oriYields[index], dayStartedRound)
              //entry.PartsQty
              entry.PartsQty = getters.partsQty(oriYields[index], dayStartedRound)
              //entry.WasteQty
              entry.WasteQty = getters.wasteQty(oriYields[index], dayStartedRound)

              //自動計算欄位
              //entry.Availability 注意：要使用稼動率(ForLine)
              // entry.Availability = getters.dayAvailability(oriYields[index], roundTotalRoundSeconds, roundTotalRestSeconds, roundTotalStopSeconds)
              entry.Availability = getters.dayAvailabilityForLine(dayYield, roundTotalLoadingSeconds, roundTotalStopSeconds)
              //entry.Performance
              entry.Performance = getters.dayPerformance(entry.GoalProduceQty, entry.RealProduceQty)
              //entry.Quality
              entry.Quality = getters.dayQuality(entry.RealProduceQty, entry.GoodQty)
              //entry.Oee
              entry.Oee = parseFloat((entry.Availability * entry.Performance * entry.Quality / 10000).toFixed(2)) //目前執行中的 (要和server算法一樣)

              realtimeYields[index] = entry
            }
          }
          
          if (entry !== undefined && entry !== null) {
            //entry.GoalCycleTime
            entry.GoalCycleTime = parseFloat(entry.GoalCycleTime.toFixed(2))
            //entry.RealCycleTime
            entry.RealCycleTime = parseFloat(entry.RealCycleTime.toFixed(2))
            //entry.GoalProduceQty
            entry.GoalProduceQty = Math.round(entry.GoalProduceQty) //要使用Math.round
            //entry.RealProduceQty
            entry.RealProduceQty = Math.ceil(entry.RealProduceQty) //要使用Math.ceil
            //entry.GoalManHourQty
            entry.GoalManHourQty = Math.ceil(entry.GoalManHourQty) //要使用Math.ceil
            //entry.RealManHourQty
            entry.RealManHourQty = Math.ceil(entry.RealManHourQty) //要使用Math.ceil
            //entry.GoalHourQty
            entry.GoalHourQty = Math.round(entry.GoalHourQty) //要使用Math.round
            //entry.RealHourQty
            entry.RealHourQty = Math.ceil(entry.RealHourQty) //要使用Math.ceil
          }
        }
      }

      return realtimeItem
    },
  }
})
