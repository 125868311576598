// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Views - Begin
const BeginIndex = () => import('@/views/Begin/Index')

// Views - Realtime
const RealtimeIndex = () => import('@/views/Realtime/Index')
const GroupIndex = () => import('@/views/Realtime/Group/Index')
const GGroup = () => import('@/views/Realtime/Group/Group')
const GCompany = () => import('@/views/Realtime/Group/Company')
const GDivision = () => import('@/views/Realtime/Group/Division')
const GSection = () => import('@/views/Realtime/Group/Section')
const GLine = () => import('@/views/Realtime/Group/Line')
const LineIndex = () => import('@/views/Realtime/Line/Index')
const LLine = () => import('@/views/Realtime/Line/Line')

// Views - Quality
const QualityIndex = () => import('@/views/Quality/Index')
const QcMaterial = () => import('@/views/Quality/QcMaterial/Index')
const QcLog = () => import('@/views/Quality/QcLog/Index')
const Runcard = () => import('@/views/Quality/Runcard/Index')
const QaMarquee = () => import('@/views/Quality/QaMarquee/Index')
const QaEmployee = () => import('@/views/Quality/QaEmployee/Index')
const QaQuestion = () => import('@/views/Quality/QaQuestion/Index')
const QaLog = () => import('@/views/Quality/QaLog/Index')

// Views - Man
const ManIndex = () => import('@/views/Man/Index')
const Employee = () => import('@/views/Man/Employee/Index')
const RoundStationTime = () => import('@/views/Man/RoundStationTime/Index')
const Course = () => import('@/views/Man/Course/Index')
const Training = () => import('@/views/Man/Training/Index')
const Postiion = () => import('@/views/Man/Position/Index')
const Department = () => import('@/views/Man/Department/Index')

// Views - Device
const DeviceIndex = () => import('@/views/Device/Index')
const MoldIndex = () => import('@/views/Device/Mold/Index')
const MSuite = () => import('@/views/Device/Mold/Suite/Index')
const MTooling = () => import('@/views/Device/Mold/Tooling/Index')
const MPart = () => import('@/views/Device/Mold/Part/Index')
// const MWorkOrder = () => import('@/views/Device/Mold/WorkOrder/Index')
// const MRepairVendor = () => import('@/views/Device/Mold/RepairVendor/Index')
// const MRepairOrder = () => import('@/views/Device/Mold/RepairOrder/Index')

// Views - Setting
const SettingIndex = () => import('@/views/Setting/Index')
const User = () => import('@/views/Setting/User/Index')
const Role = () => import('@/views/Setting/Role/Index')
const OrganizationIndex = () => import('@/views/Setting/Organization/Index')
const OGroup = () => import('@/views/Setting/Organization/Group/Index')
const OCompany = () => import('@/views/Setting/Organization/Company/Index')
const ODivision = () => import('@/views/Setting/Organization/Division/Index')
const OSection = () => import('@/views/Setting/Organization/Section/Index')
const OLine = () => import('@/views/Setting/Organization/Line/Index')
const OStation = () => import('@/views/Setting/Organization/Station/Index')

// Views - Stock
const StockIndex = () => import('@/views/Stock/Index')
const Material = () => import('@/views/Stock/Material/Index')
const Location = () => import('@/views/Stock/Location/Index')
const PostingIndex = () => import('@/views/Stock/Posting/Index')
const PYield = () => import('@/views/Stock/Posting/Yield/Index')

// Views - Output
const OutputIndex = () => import('@/views/Output/Index')

// Views - Customer
const CustomerIndex = () => import('@/views/Customer/Index')

export default [
  {
     // 首頁
    path: '/',
    redirect: '/Dashboard',
    name: 'Router.Home',
    component: DefaultContainer,
    children: [
      // 儀表板
      { path: '/Dashboard', name: 'Router.Dashboard', component: Dashboard },
      {
        // 始業設定
        path: '/Begin',
        redirect: '/Begin/Index',
        name: 'Router.Begin',
        component: { render (c) { return c('router-view') } },
        children: [
          // 索引
          { path: 'Index', name: 'Router.BeginIndex', component: BeginIndex },
        ]
      },
      {
        // 即時系統
        path: '/Realtime',
        redirect: '/Realtime/Index',
        name: 'Router.Realtime',
        component: { render (c) { return c('router-view') } },
        children: [
          // 索引
          { path: 'Index', name: 'Router.RealtimeIndex', component: RealtimeIndex },
          {
            // 集團
            path: '/Realtime/Group',
            redirect: '/Realtime/Group/Index',
            name: 'Router.RealtimeGroup',
            component: { render (c) { return c('router-view') } },
            children: [
              // 索引
              { path: 'Index', name: 'Router.GroupIndex', component: GroupIndex },
              // 集團別
              { path: 'Group', name: 'Router.GroupGroup', component: GGroup },
              // 公司別
              { path: 'Company', name: 'Router.GroupCompany', component: GCompany },
              // 廠別
              { path: 'Division', name: 'Router.GroupDivision', component: GDivision },
              // 課別
              { path: 'Section', name: 'Router.GroupSection', component: GSection },
              // 線別
              { path: 'Line', name: 'Router.GroupLine', component: GLine }
            ]
          },
          {
            // 線別
            path: '/Realtime/Line',
            redirect: '/Realtime/Line/Index',
            name: 'Router.RealtimeLine',
            component: { render (c) { return c('router-view') } },
            children: [
              // 索引
              { path: 'Index', name: 'Router.LineIndex', component: LineIndex },
              // 線別
              { path: 'Line', name: 'Router.LineLine', component: LLine }
            ]
          }
        ]
      },
      {
        // 品質系統
        path: '/Quality',
        redirect: '/Quality/Index',
        name: 'Router.Quality',
        component: { render (c) { return c('router-view') } },
        children: [
          // 索引
          { path: 'Index', name: 'Router.QualityIndex', component: QualityIndex },
          // 料號檢驗項目設定
          { path: 'QcMaterial', name: 'Router.QualityQcMaterial', component: QcMaterial },
          // 料號檢驗紀錄
          { path: 'QcLog', name: 'Router.QualityQcLog', component: QcLog },
          // 回溯查詢
          { path: 'Runcard', name: 'Router.QualityRuncard', component: Runcard },
          // 品管看板跑馬燈
          { path: 'QaMarquee', name: 'Router.QualityQaMarquee', component: QaMarquee },
          // 品管看板點檢人員
          { path: 'QaEmployee', name: 'Router.QualityQaEmployee', component: QaEmployee },
          // 品管點檢項目設定
          { path: 'QaQuestion', name: 'Router.QualityQaQuestion', component: QaQuestion },
          // 品管點檢紀錄
          { path: 'QaLog', name: 'Router.QualityQaLog', component: QaLog },
        ]
      },
      {
        // 人員管理
        path: '/Man',
        redirect: '/Man/Index',
        name: 'Router.Man',
        component: { render (c) { return c('router-view') } },
        children: [
          // 索引
          { path: 'Index', name: 'Router.ManIndex', component: ManIndex },
          // 人員基本資料設定
          { path: 'Employee', name: 'Router.ManEmployee', component: Employee },
          // 線上履歷
          { path: 'RoundStationTime', name: 'Router.ManRoundStationTime', component: RoundStationTime },
          // 教育訓練項目設定
          { path: 'Course', name: 'Router.ManCourse', component: Course },
          // 教育訓練紀錄
          { path: 'Training', name: 'Router.ManTraining', component: Training },
          // 職等設定
          { path: 'Position', name: 'Router.ManPosition', component: Postiion },
          // 部門設定
          { path: 'Department', name: 'Router.ManDepartment', component: Department }
        ]
      },
      {
        // 設備管理
        path: '/Device',
        redirect: '/Device/Index',
        name: 'Router.Device',
        component: { render (c) { return c('router-view') } },
        children: [
          // 索引
          { path: 'Index', name: 'Router.DeviceIndex', component: DeviceIndex },
          {
            // 模具治具管理
            path: '/Device/Mold',
            redirect: '/Device/Mold/Index',
            name: 'Router.Mold',
            component: { render (c) { return c('router-view') } },
            children: [
              // 索引
              { path: 'Index', name: 'Router.MoldIndex', component: MoldIndex },
              // 模具組別基本資料維護
              { path: 'Suite', name: 'Router.MoldSuite', component: MSuite },
              // 模具基本資料維護
              { path: 'Tooling', name: 'Router.MoldTooling', component: MTooling },
              // 零件基本資料維護
              { path: 'Part', name: 'Router.MoldPart', component: MPart }
              // 報工紀錄維護
              // { path: 'WorkOrder', name: 'Router.MoldWorkOrder', component: MWorkOrder },
              // 維修廠商資料維護
              // { path: 'RepairVendor', name: 'Router.MoldRepairVendor', component: MRepairVendor },
              // 維修紀錄維護
              // { path: 'RepairOrder', name: 'Router.MoldRepairOrder', component: MRepairOrder }
            ]
          }
        ]
      },
      {
        // 系統管理
        path: '/Setting',
        redirect: '/Setting/Index',
        name: 'Router.Setting',
        component: { render (c) { return c('router-view') } },
        children: [
          // 索引
          { path: 'Index', name: 'Router.SettingIndex', component: SettingIndex },
          // 使用者帳號設定
          { path: 'User', name: 'Router.SettingUser', component: User },
          // 角色權限設定
          { path: 'Role', name: 'Router.SettingRole', component: Role },
          {
            // 集團管理
            path: '/Setting/Organization',
            redirect: '/Setting/Organization/Index',
            name: 'Router.Organization',
            component: { render (c) { return c('router-view') } },
            children: [
              // 索引
              { path: 'Index', name: 'Router.OrganizationIndex', component: OrganizationIndex },
              // 集團別設定
              { path: 'Group', name: 'Router.OrganizationGroup', component: OGroup },
              // 公司別設定
              { path: 'Company', name: 'Router.OrganizationCompany', component: OCompany },
              // 廠別設定
              { path: 'Division', name: 'Router.OrganizationDivision', component: ODivision },
              // 課別設定
              { path: 'Section', name: 'Router.OrganizationSection', component: OSection },
              // 線別設定
              { path: 'Line', name: 'Router.OrganizationLine', component: OLine },
              // 站別設定
              { path: 'Station', name: 'Router.OrganizationStation', component: OStation }
            ]
          }
        ]
      },
      {
        // 庫存管理
        path: '/Stock',
        redirect: '/Stock/Index',
        name: 'Router.Stock',
        component: { render (c) { return c('router-view') } },
        children: [
          // 索引
          { path: 'Index', name: 'Router.StockIndex', component: StockIndex },
          // 關鍵零件設定
          { path: 'Material', name: 'Router.Material', component: Material },
          // 儲位管理
          { path: 'Location', name: 'Router.Location', component: Location },
          {
            // 自動過帳
            path: '/Stock/Posting',
            redirect: '/Stock/Posting/Index',
            name: 'Router.Posting',
            component: { render (c) { return c('router-view') } },
            children: [
              // 索引
              { path: 'Index', name: 'Router.PostingIndex', component: PostingIndex },
              // 報工資料
              { path: 'Yield', name: 'Router.PostingYield', component: PYield }
            ]
          }
        ]
      },
      {
        // 生產管理
        path: '/Output',
        redirect: '/Output/Index',
        name: 'Router.Output',
        component: { render (c) { return c('router-view') } },
        children: [
          // 索引
          { path: 'Index', name: 'Router.OutputIndex', component: OutputIndex },
        ]
      },
      {
        // 客戶服務
        path: '/Customer',
        redirect: '/Customer/Index',
        name: 'Router.Customer',
        component: { render (c) { return c('router-view') } },
        children: [
          // 索引
          { path: 'Index', name: 'Router.CustomerIndex', component: CustomerIndex },
        ]
      },
    ]
  }
]
