import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { CONFIG as SiteConfig } from '@/config'

Vue.use(VueI18n)

const locale = SiteConfig.Locale
const messages = {
  'zh-TW': require('./locale/zh-TW.json'),
  'en-US': require('./locale/en-US.json')
}
const dateTimeFormats = {
  'zh-TW': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true
    }
  },
  'en-US': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric'
    }
  }
}


const i18n = new VueI18n({
  locale,
  messages,
  dateTimeFormats
})

export default i18n
